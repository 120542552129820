/* You can add global styles to this file, and also import other style files */
:root{
    --colorPrimario: #385EA7;
    --b: white;
    --c: #FFE828;
}

.cl-a{
    color: var(--colorPrimario);
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.card-container{
    // padding: 2em;
    &.encuesta{
        padding-top: calc(2em + 80px);
    }    
    .mat-card{
        box-shadow: none;
        .mat-card-header-text{
            margin: 0;
        }
    }
}
.spinner{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0.85);
    padding: 0 !important;
    .mat-spinner{
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        border-radius: 50%;
    }
}
.text-align-center{text-align: center;}
table{width: 100%;}
.bt{
    display: inline-block;
    padding-right: 0.5em;
    &.col-md-12{
        width: calc(100% - 0.5em);
    }
    &.col-md-11{
        width: calc(calc(calc(100% / 12) * 11) - 0.5em);
    }
    &.col-md-10{
        width: calc(calc(calc(100% / 12) * 10) - 0.5em);
    }
    &.col-md-9{
        width: calc(calc(calc(100% / 12) * 9) - 0.5em);
    }
    &.col-md-8{
        width: calc(calc(calc(100% / 12) * 8) - 0.5em);
    }
    &.col-md-7{
        width: calc(calc(calc(100% / 12) * 7) - 0.5em);
    }
    &.col-md-6{
        width: calc(calc(calc(100% / 12) * 6) - 0.5em);
    }
    &.col-md-5{
        width: calc(calc(calc(100% / 12) * 5) - 0.5em);
    }
    &.col-md-4{
        width: calc(calc(calc(100% / 12) * 4) - 0.5em);
    }
    &.col-md-3{
        width: calc(calc(calc(100% / 12) * 3) - 0.5em);
    }
    &.col-md-2{
        width: calc(calc(calc(100% / 12) * 2) - 0.5em);
    }
    &.col-md-1{
        width: calc(calc(calc(100% / 12) * 1) - 0.5em);
    }
}

.cdk-column-position{
    padding-left: 0px !important;
    min-width: 30px !important;
}

.no-padding{
    padding: 0px !important;
  }

.mat-mini-fab.mat-accent {
    background-color: #2F86A6;
    // background-color: #5d89c3;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
    background-color: #2F86A6;
    // background-color: #5d89c3;
}

// .mat-flat-button{
//     // background-color: #385ea7 !important;
//     background-color: #385ea7;
// }

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent{
    background-color: #2F86A6;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #385ea7;
}

.txt-subtotal{
    font-size: 20px;
    padding-bottom: 5px;
    font-weight: 500;
}
.txt-total{
    font-size: 26px;
    font-weight: 400;
}

.btn-icon-small{
    height: 30px !important;
    width: 30px !important;
}
.icon-small{
    font-size: 19px;
    line-height: 13px;
}

.input-full-width {
    width: 100%;
  }

  .input-full-w {
    width: 100%;
  }
  